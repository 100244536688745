@import "./App-css-variable.scss";

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"), url("./fonts/Roboto-Bold.ttf") format("tff");
}

@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto Regular"), url("./fonts/Roboto-Regular.ttf") format("tff");
}
@font-face {
  font-family: "Roboto Bold Italic";
  src: local("Roboto Bold Italic"), url("./fonts/Roboto-BoldItalic.ttf") format("tff");
}

@font-face {
  font-family: "Roboto Light";
  src: local("Roboto Light"), url("./fonts/Roboto-Light.ttf") format("tff");
}
@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto Medium"), url("./fonts/Roboto-Medium.ttf") format("tff");
}

// * {
//   font-family: 'Axiforma SemiBold' !important;
// }

.loading-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.app-view--wrapper.dark {
  header {
    color: #fff;
    background-color: #1b1b1b !important;
  }
  .react-select-style {
    &__control {
      background-color: #666 !important;
    }

    &__single-value {
      color: #fff !important;
      font-size: 14px;
    }
    &__single-value--is-disabled {
      color: #888 !important;
    }
    &__control__single-value {
      color: #fff !important;
    }
    &__control--is-disabled {
      border-color: #222;
      background-color: #333 !important;
    }
    &__option {
      background-color: #fff !important;
      color: #444;
    }
    &__option:hover {
      background-color: #666 !important;
      color: #fff;
    }
  }

  // input {
  //   background-color: #666 !important;
  // }
  .MuiAvatar-colorDefault {
    color: #f2f2f2;
  }
}

.app-view--wrapper.light {
  header {
    color: #444 !important;
    background-color: #fff !important;
  }
}

// .app-view--wrapper.admin.light {
//   header {
//     color: #444 !important;
//     background-color: #fff !important;
//   }

//   .MuiAvatar-colorDefault {
//     color: #555;
//     border: 1px solid #f2f2f2;
//   }

//   header#admin_header {
//     // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
//     color: #fff !important;
//     background-color: #d44842 !important;
//     //background-color: #fff !important;
//     button {
//       border-color: #fff;
//       color: #fff;
//     }
//     input[disabled] {
//       // color: #fff;
//     }
//   }
// }

.app-view--wrapper.light {
  header {
    color: #444 !important;
    background-color: #fff !important;
  }
}

// .app-view--wrapper.instructor.light {
//   header {
//     color: #444 !important;
//     background-color: #fff !important;
//   }

//   .MuiAvatar-colorDefault {
//     color: #555;
//     border: 1px solid #f2f2f2;
//   }

//   header#instructor_header {
//     // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
//     color: #fff !important;
//     background-color: #048f98 !important;
//     //background-color: #fff !important;
//     button {
//       border-color: #fff;
//       color: #fff;
//     }
//     input[disabled] {
//       // color: #fff;
//     }
//   }
// }

// .app-view--wrapper.learner.light {
//   header {
//     color: #444 !important;
//     background-color: #fff !important;
//   }

//   .MuiAvatar-colorDefault {
//     color: #555;
//     border: 1px solid #f2f2f2;
//   }

//   header#learning_header {
//     // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
//     color: #fff !important;
//     background-color: #315390 !important;
//     //background-color: #fff !important;
//     button {
//       border-color: #fff;
//       color: #fff;
//     }
//     input[disabled] {
//       // color: #fff;
//     }
//   }
// }

.MuiSkeleton-text {
  transform: none !important;
}

.MuiSkeleton-text {
  margin: 5px 0px !important;
}

.t-clamp-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.t-clamp-max-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

* {
  outline: none !important;
}

a {
  text-decoration: none;
}

[placeholder] {
  text-overflow: ellipsis;
}

ul .MuiListItem-button.with-icon {
  .MuiListItemIcon-root {
    min-width: 35px;
    svg {
      font-size: 23px;
      // color: $app_color_8;
    }
  }

  .MuiTypography-body1,
  p {
    font-size: 14px;
  }
}

.MuiMenu-paper {
  border-radius: 10px;
}
button.curve {
  border-radius: 30px;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

header.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.04),
    0px 1px 10px 0px rgba(0, 0, 0, 0.03);
}

header {
  .MuiTabs-indicator {
    height: 4px;
  }
}

.t-clamp-max-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.link {
  color: #0a66c2;
}

.MuiListItem-root.with-icon .MuiListItemIcon-root {
  min-width: 35px;
}

.app-view--wrapper.dark {
  .divider-between-title {
    margin: 23px 25px;
    margin-left: 10px;
    width: 2px;
    background-color: rgba(253, 253, 253, 0.5);
  }
}

.app-view--wrapper.light {
  .divider-between-title {
    margin: 23px 25px;
    margin-left: 10px;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .MuiAvatar-colorDefault {
    color: inherit;
    background-color: #e4e6eb;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(175, 175, 175, 0.1);
  }

  .MuiChip-root.default-contained {
    background-color: #e4e6eb;
  }
}

.page-breadcrumbs {
  .MuiBreadcrumbs-separator {
    display: flex;
    margin-left: 8px;
    user-select: none;
    margin-right: 8px;
    font-size: 12px;
  }
  .a {
    font-size: 12px;
  }
}

.banner--wrapper {
  .banner--group {
    .banner--inner {
      width: 100%;
      .banner-image {
        width: 100%;
      }
    }
  }
}

.MuiDialogTitle-root {
  margin: 16px 24px !important;
  padding: 0px !important;
}

.MuiPaper-root.video-item-wrapper {
  background-color: transparent;
  p {
    margin: 0px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 0px;
  }

  .item-created-at {
    .MuiChip-root {
      height: initial;
      .MuiChip-label {
        span {
          font-size: 10px;
        }
      }
    }
  }
}

.app-view--wrapper.dark {
  .MuiPaper-root.video-item-wrapper:hover,
  .MuiPaper-root.video-item-wrapper:focus {
    background-color: #424242 !important;
  }
}

.app-view--wrapper.light {
  .MuiPaper-root.video-item-wrapper:hover,
  .MuiPaper-root.video-item-wrapper:focus {
    background-color: #fff !important;
  }
}

.breadcrumbs-menulist--wrapper {
  padding: 5px 10px !important;
  background-color: #f9f9f9 !important;
  box-shadow: none !important;
}
